<template>
  <div class="tablePrint">
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col class="m-d-center mb-sm-2" :xs="24" :md="12" :lg="12" :xl="12">{{ $t("message.procedural_list") }} </el-col>

        <el-col :xs="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                size="small"
                class="mr-1"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save") }}
              </el-button>

              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="row">
      <div class="col-12">
        <el-card class="box-card mt-2">
          <el-collapse class="open_cliet_hstori mb-4" v-model="activeCollapse">
            <el-collapse-item :title="$t('message.patient_info')" name="1">
              <el-row>
                <el-col :span="6">
                  {{ $t("message.fio") }}:
                  <b>{{
                    patient
                      ? patient.surname +
                        " " +
                        patient.first_name +
                        " " +
                        (patient.patronymic ? patient.patronymic : "")
                      : ""
                  }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.phone") }}:
                  <b>{{ patient.phone ? patient.phone : "-" | formatPhoneNumber}}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.dob") }}:
                  <b>{{ patient ? patient.born_date : "-" }}</b>
                </el-col>
                <el-col :span="6">
                  {{ $t("message.gender") }}:
                  <b>{{ patient ? patient.gender : "-" }}</b>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
          <el-collapse
            class="open_cliet_hstori pt-0 mb-4"
            v-model="activeCollapse2"
          >
            <el-collapse-item :title="$t('message.order_service')" name="1">
              <ul class="ul_usluga">
                <li v-for="(item, i) in order_services" :key="i">
                  <div>-{{ item.name }}</div>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </div>
    </div>

    <div class="p-3">
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label"
            ><i class="el-icon-takeaway-box"></i> {{ $t("message.products") }}</span
          >
          <div class="center__block_form m-0 p-0">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              class="aticler_m m-0 p-0"
              size="small"
            >
              <el-row :gutter="25">
                <el-col :span="24">
                  <el-form-item
                    :label="$t('message.main_warehouses')"
                    prop="warehouse_id"
                  >
                    <warehouse
                      v-model="warehouse_id"
                      :warehouse_id="warehouse_id"
                      size="mini"
                    ></warehouse>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <!-- end col -->
              </el-row>
              <!-- end el-row -->

              <div class="mb-4">
                <div>
                  <el-table
                    border
                    :data="items"
                    :row-class-name="tableRowClassName"
                  >
                    <el-table-column label="#" width="30">
                      <template slot-scope="scope">{{
                        scope.$index + 1
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.name')">
                      <template slot-scope="scope">{{
                        scope.row.product.name
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.available')">
                      <template slot-scope="scope">{{
                        scope.row.available
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.quantity')">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.quantity"
                          type="number"
                          :min="0"
                          :max="scope.row.available"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.actions')">
                      <template slot-scope="scope">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="removeWriteOffItem(scope.row)"
                        ></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <el-row :gutter="25">
                  <el-col :span="11" class="mt-3">
                    <el-form-item
                      v-if="warehouse_id"
                      :label="$t('message.products')"
                    >
                      <products
                        @append="append"
                        v-model="form.product_id"
                        :product_id="form.product_id"
                      ></products>
                    </el-form-item>
                    <el-form-item v-else :label="$t('message.products')">
                      <el-select
                        v-model="value"
                        disabled
                        :placeholder="$t('message.products')"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="брак ">
          <span slot="label"><i class="el-icon-document-delete"></i> {{ $t("message.invalid") }}</span>
          <div class="center__block_form m-0 p-0">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              class="aticler_m m-0 p-0"
              size="small"
            >
              <el-row :gutter="25">
                <el-col :span="24">
                  <el-form-item
                    :label="$t('message.main_warehouses')"
                    prop="invalid_warehouse_id"
                  >
                    <warehouse
                      v-model="invalid_warehouse_id"
                      :warehouse_id="invalid_warehouse_id"
                      size="mini"
                    ></warehouse>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <!-- end col -->
              </el-row>
              <!-- end el-row -->

              <div class="mb-4">
                <div>
                  <el-table
                    border
                    :data="invalid_items"
                    :row-class-name="tableRowClassName"
                  >
                    <el-table-column label="#" width="30">
                      <template slot-scope="scope">{{
                        scope.$index + 1
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.name')">
                      <template slot-scope="scope">{{
                        scope.row.product.name
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.available')">
                      <template slot-scope="scope">{{
                        scope.row.available
                      }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('message.quantity')">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.quantity"
                          type="text"
                          @input="correctNumber(scope.row, 'quantity')"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.comment')">
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.comment"
                          type="text"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.actions')">
                      <template slot-scope="scope">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="removeWriteOffInvalidItem(scope.row)"
                        ></el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <el-row :gutter="25">
                  <el-col :span="11" class="mt-3">
                    <el-form-item
                      v-if="invalid_warehouse_id"
                      :label="$t('message.products')"
                    >
                      <products
                        @append="invalid_append"
                        v-model="form.invalid_product_id"
                        :product_id="form.invalid_product_id"
                      ></products>
                    </el-form-item>
                    <el-form-item v-else :label="$t('message.products')">
                      <el-select
                        v-model="value"
                        disabled
                        :placeholder="$t('message.products')"
                      >
                        <el-option
                          v-for="item in invalid_options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import warehouse from "@/components/selects/warehouse";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";
import products from "@/components/selects/products";

export default {
  mixins: [drawerChild, form],
  props: ["selected"],

  components: {
    warehouse,
    products,
  },
  data() {
    return {
      fileList: [],
      favorites: [],
      options: [],
      invalid_options: [],
      activeCollapse: "",
      activeCollapse2: "",
      loadingButton: false,
      value: "",
      data: [],
      items: [],
      old_items: [],
      patient: [],
      invalid_items: [],
      old_invalid_items: [],
      deletedItem: [],
      deletedInvalidItem: [],
      order_services: [],
      warehouse_id: null,
      invalid_warehouse_id: null,
      // patientMedicine: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    form: {
      handler: function () {},
    },
  },
  computed: {
    ...mapGetters({
      // tempList: "formTemplate/all",
      rules: "conclusions/rules",
      model: "conclusions/model",
      order_model: "orders/relation_model",
      columns: "conclusions/columns",
      patientMedicine: "patientMedicine/model_order",
    }),
  },
  create () {
    this.correctNumber(scope.row, 'quantity');
  },
  methods: {
    ...mapActions({
      empty: "patientMedicine/empty",
      showProductRemainder: "movings/showrem",
      showMedicine: "patientMedicine/patientMedicineShowOrder",
      showModel: "patientMedicine/patientMedicineShowOrder",
      loadPartnerDoctors: "patientMedicine/patientMedicineUpdate",
      save: "orders/patientMedicine",
    }),
    categoryDocumentation() {
      this.categoryDocumentationList({ relation: true })
        .then((res) => {
          this.data = res.data.category_forms;
        })
        .catch((err) => {});
    },
    afterOpened() {
      this.showMedicine(this.selected.id)
        .then(async (res) => {
          this.form = JSON.parse(JSON.stringify(this.model));
          this.updateForm(res.data.patient_medicine);
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    updateForm(res) {
      this.showModel(this.selected.id)
        .then((ress) => {
          this.order_services = ress.data.patient_medicine.order.order_services;
          this.patient = ress.data.patient_medicine.patient;
        })
        .catch((err) => {
          console.log(err);
        });
      this.invalid_items = res.items.filter((val) => val.is_invalid == true);
      this.items = res.items.filter((val) => val.is_invalid == false);

      this.invalid_items.forEach((val) =>
        this.old_invalid_items.push({
          id: val.id,
          warehouse_id: val.warehouse_id,
          product_id: val.product_id,
          quantity: val.quantity,
        })
      );
      this.items.forEach((val) =>
        this.old_items.push({
          id: val.id,
          warehouse_id: val.warehouse_id,
          product_id: val.product_id,
          quantity: val.quantity,
        })
      );

      this.form.invalid_warehouse_id = res.items.filter(
        (val) => val.is_invalid == true
      ).length
        ? res.items.filter((val) => val.is_invalid == true)[0].warehouse_id
        : null;
      this.form.warehouse_id = res.items.filter(
        (val) => val.is_invalid == false
      ).length
        ? res.items.filter((val) => val.is_invalid == false)[0].warehouse_id
        : null;
      this.invalid_warehouse_id = res.items.filter(
        (val) => val.is_invalid == true
      ).length
        ? res.items.filter((val) => val.is_invalid == true)[0].warehouse_id
        : null;
      this.warehouse_id = res.items.filter((val) => val.is_invalid == false)
        .length
        ? res.items.filter((val) => val.is_invalid == false)[0].warehouse_id
        : null;

      this.items.forEach(
        (item) =>
          (item.available = item.product.available_product.filter(
            (val) => val.warehouse_id == item.warehouse_id
          )[0].remainder)
      );

      this.invalid_items.forEach(
        (item) =>
          (item.available = item.product.available_product.filter(
            (val) => val.warehouse_id == item.warehouse_id
          )[0].remainder)
      );
    },
    append(product) {
      const item = this.items.find((item) => item.product_id == product.id);
      this.loadingItems = true;
      const payload = {
        product_id: product.id,
        warehouse_id: this.warehouse_id,
      };
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.items.push({
              product_id: product.id,
              available: product.available ? product.available : 0,
              quantity: 0,
              product: {
                name: product.name,
              },
            });
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    invalid_append(product) {
      const item = this.invalid_items.find(
        (item) => item.product_id == product.id
      );
      this.loadingItems = true;
      const payload = {
        product_id: product.id,
        warehouse_id: this.invalid_warehouse_id,
      };
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.invalid_items.push({
              product_id: product.id,
              available: product.available ? product.available : 0,
              quantity: 0,
              product: {
                name: product.name,
              },
            });
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const payload = {
            warehouse_id: this.form.warehouse_id,
            comment: this.form.comment,
            invalid_warehouse_id: this.form.invalid_warehouse_id,
            old_items: this.old_items,
            old_invalid_items: this.old_invalid_items,
            items: this.items,
            invalid_items: this.invalid_items,
            patient_id: this.patientMedicine.patient.id,
            order_id: this.patientMedicine.order_id,
            id: this.patientMedicine.order_id,
            deletedInvalidItem: this.deletedInvalidItem,
            deletedItem: this.deletedItem,
          };
          this.loadPartnerDoctors(payload)
            .then((response) => {
              this.$alert(response);
              if (close) this.close();
            })
            .catch((error) => {
              this.$alert(error);
            });
        }
      });
    },
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
      this.deletedItem.push(item.id);
    },
    removeWriteOffInvalidItem(item) {
      const index = this.invalid_items.indexOf(item);
      this.invalid_items.splice(index, 1);
      this.deletedInvalidItem.push(item.id);
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = null;
      this.items = [];
      this.old_form = "";
      this.active = 0;
      this.items = [];
      this.invalid_items = [];
      this.old_items = [];
      this.old_invalid_items = [];
      this.activeName = "first";
      this.templates = [];
      this.selectedTemplate = {};
      this.nextButton = true;
      // this.$emit("c-close", { drawer: "drawerUpdate" });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return "warning-row";
      }
    },
  
    afterClosed() {
      this.empty();
      // this.form.warehouse_id = null;
      // this.form.invalid_warehouse_id = null;
      this.form = null;
      this.items = [];
      this.invalid_items = [];
      this.old_items = [];
      this.old_invalid_items = [];
      this.deletedInvalidItem = [];
      this.deletedItem = [];

      this.warehouse_id = null;
      this.invalid_warehouse_id = null;
    },
    chanseService(id) {
      this.form.service_id = id;
    },
    correctNumber(obj, propertyName) {
      let unformattedValue = obj[propertyName].toString().replace(/\s/g, '');
      let formattedValue = unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      // Обновляем значение в объекте
      this.$set(obj, propertyName, formattedValue);
    },
  },
};
</script>